.settings-modal-container {
  position: absolute;
  top: 0;
  left: 49px;
  background-color: #fff;
  padding: 10px;
  box-shadow: #cdcdcd 1px 1px 5px;
  border-radius: @border-radius-base;
  .brush-container {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    .brush-item {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      white-space: nowrap;
      .brush-title {
        margin-left: 5px;
        font-size: @font-size-base;
        color: #828282;
      }
    }
    .active {
      background-color: #020202;
      border-radius: @border-radius-base * 2;
      .brush-title {
        color: #fff;
      }
    }
  }
  .stroke-container {
    padding: 10px 0;
    border-top: 1px solid #cdcdcd;
    .stroke-title {
      font-size: @font-size-base;
      color: #828282;
    }
  }
  .color-picker-container {
    .custom-color-picker {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      margin-bottom: 10px;
      .color-item {
        position: relative;
        width: 28px;
        height: 28px;
        margin: 5px;
        .color,
        .active-cover {
          display: inline-block;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          border-radius: 14px;
          cursor: pointer;
        }
        .color:hover {
          border: 14px solid #7f7c7c60;
        }
        .active-cover {
          position: absolute;
          top: 0;
          left: 0;
          align-items: center;
          justify-content: center;
          background-color: #c8c8c802;
        }
      }
    }
  }
}

@border-radius-base: 7px;@font-size-base: 17px;