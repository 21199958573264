.state-container {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  .zoom-container,
  .zen-container,
  .drag-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
    box-shadow: #cdcdcd 1px 1px 5px;
    border-radius: @border-radius-base;
    padding: 5px;
  }
  .zoom-container {
    .zoom-size {
      width: 4em;
      font-size: @font-size-base;
      border-left: #cdcdcd 1px solid;
      border-right: #cdcdcd 1px solid;
      text-align: center;
    }
  }
  .zen-container {
    margin-left: 5px;
  }
  .drag-container {
    margin-right: 5px;
  }
}

@border-radius-base: 7px;@font-size-base: 17px;