.tool-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .tool-title {
    position: absolute;
    top: 0;
    left: 0;
    left: 27px;
    display: none;
    padding: 2px 5px;
    border-radius: @border-radius-base;
    background-color: #828282;
    opacity: 0.8;
    white-space: nowrap;
    font-size: @font-size-base;
    color: #fff;
  }
  &:hover {
    cursor: pointer;
    .tool-title {
      display: block;
    }
  }
}

@border-radius-base: 7px;@font-size-base: 17px;