.tools-container {
  display: inline-block;
  position: fixed;
  z-index: 100;
  left: 10px;
  top: 32%;
  .base-tools {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: #cdcdcd 1px 1px 5px;
    border-radius: @border-radius-base;
    padding: 10px;
    .tool {
      position: relative;
      margin: 5px;
    }
  }
}

@border-radius-base: 7px;@font-size-base: 17px;