::-webkit-scrollbar {
  width: 0px;
}
body {
  overflow: hidden;
}
.main {
  position: relative;
}

@border-radius-base: 7px;@font-size-base: 17px;